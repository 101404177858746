var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c("div", { staticClass: "flex flex-col gap-4" }, [
            _c("div", { staticClass: "pb-8" }, [
              _c("div", { staticClass: "flex flex-col gap-2" }, [
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("select-suggestion", {
                      ref: "select_questionnaire",
                      class: _vm.questionnaire
                        ? "flex-grow"
                        : "flex-grow required",
                      attrs: {
                        max: 20,
                        column: "name,description",
                        model: "ContentQuestionnaire",
                        label: _vm.$t("Questionnaire"),
                        placeholderText: "Digite o nome...",
                      },
                      on: { clearSelection: _vm.clearData },
                      model: {
                        value: _vm.questionnaire,
                        callback: function ($$v) {
                          _vm.questionnaire = $$v
                        },
                        expression: "questionnaire",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("questionnaire_id"),
                            expression: "errors.has('questionnaire_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("questionnaire_id")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.questionnaire
            ? _c("questionnaire-information", {
                attrs: { questionnaire: _vm.questionnaire },
              })
            : _vm._e(),
          _c("div", { staticClass: "vx-row mt-4" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "float-right mr-2",
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.loadData },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.consult")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "vs-tabs",
        [
          _vm.enableTabQuestionnaire
            ? _c(
                "vs-tab",
                { attrs: { label: _vm.$t("backoffice.questionnaire_title") } },
                [
                  _c(
                    "vx-card",
                    {
                      ref: "results",
                      staticClass: "mb-4",
                      attrs: {
                        title: _vm.$t("resultados"),
                        "collapse-action": "",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("Grid", {
                            ref: "answersGrid",
                            attrs: {
                              service: _vm.questionnaireAnswerService,
                              route_name: "questionnaires_answers",
                              route_grid_path: _vm.answersResultsPath,
                              order_column: "finished_at",
                              direction: "desc",
                              fetchOnMounted: false,
                              showTotalText: "Total",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "gridThead",
                                  fn: function () {
                                    return [
                                      _c(
                                        "vs-th",
                                        {
                                          key: "id",
                                          attrs: { "sort-key": "id" },
                                        },
                                        [_vm._v("ID")]
                                      ),
                                      _c(
                                        "vs-th",
                                        {
                                          key: "user_name",
                                          attrs: { "sort-key": "user_name" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("usuario")))]
                                      ),
                                      _c(
                                        "vs-th",
                                        {
                                          key: "user_email",
                                          attrs: { "sort-key": "user_email" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("email")))]
                                      ),
                                      _c(
                                        "vs-th",
                                        {
                                          key: "created_at",
                                          attrs: { "sort-key": "created_at" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("data-hora-inicio"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "vs-th",
                                        {
                                          key: "finished_at",
                                          attrs: { "sort-key": "finished_at" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("data-hora-termino"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "gridData",
                                  fn: function (props) {
                                    return _vm._l(
                                      props.data,
                                      function (tr, indextr) {
                                        return _c(
                                          "vs-tr",
                                          {
                                            key: indextr,
                                            class:
                                              "" +
                                              (tr.finished_at === null
                                                ? "text-warning"
                                                : ""),
                                            attrs: { data: tr },
                                          },
                                          [
                                            _c("vs-td", { key: "id" }, [
                                              _vm._v(_vm._s(tr.id)),
                                            ]),
                                            _c("vs-td", { key: "user_name" }, [
                                              _vm._v(_vm._s(tr.user_name)),
                                            ]),
                                            _c("vs-td", { key: "user_email" }, [
                                              _vm._v(_vm._s(tr.user_email)),
                                            ]),
                                            _c("vs-td", { key: "created_at" }, [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.createdFormated(tr)
                                                  ),
                                                },
                                              }),
                                            ]),
                                            _c("vs-td", { key: "finish_at" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    tr.finished_at_formatted
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "vs-td",
                                              { attrs: { width: "10%" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex items-center justify-center",
                                                  },
                                                  [
                                                    _c(
                                                      "vs-dropdown",
                                                      {
                                                        staticClass:
                                                          "cursor-pointer",
                                                        attrs: {
                                                          "vs-custom-content":
                                                            "",
                                                          "vs-trigger-click":
                                                            "",
                                                        },
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            type: "filled",
                                                            icon: "MoreVerticalIcon",
                                                          },
                                                        }),
                                                        _c(
                                                          "vs-dropdown-menu",
                                                          [
                                                            !tr.finished_at
                                                              ? _c(
                                                                  "vs-dropdown-item",
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "permission",
                                                                              rawName:
                                                                                "v-permission",
                                                                              value:
                                                                                "backoffice.questionnaire.force_finish",
                                                                              expression:
                                                                                "'backoffice.questionnaire.force_finish'",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "flex",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.confirmForceFinish(
                                                                                tr
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "w-2/12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "feather-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: "CheckCircleIcon",
                                                                                    svgClasses:
                                                                                      "text-success h-5 w-5 mb-1 stroke-current",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "w-10/12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "flex bg-gray-200 float-left",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "action.force_finish"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            tr.finished_at
                                                              ? _c(
                                                                  "vs-dropdown-item",
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "permission",
                                                                              rawName:
                                                                                "v-permission",
                                                                              value:
                                                                                "backoffice.questionnaire.rewinded",
                                                                              expression:
                                                                                "'backoffice.questionnaire.rewinded'",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "flex",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.confirmResetAnswer(
                                                                                tr
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "w-2/12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "feather-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: "RepeatIcon",
                                                                                    svgClasses:
                                                                                      "text-success h-5 w-5 mb-1 stroke-current",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "w-10/12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "flex bg-gray-200 float-left",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "action.second_chance"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "vs-dropdown-item",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "permission",
                                                                          rawName:
                                                                            "v-permission",
                                                                          value:
                                                                            "backoffice.questionnaire.rewinded",
                                                                          expression:
                                                                            "'backoffice.questionnaire.rewinded'",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "flex",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "150px",
                                                                      },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.confirmResetAnswerToBlank(
                                                                            tr
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-2/12",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "feather-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "RepeatIcon",
                                                                                svgClasses:
                                                                                  "text-danger h-5 w-5 mb-1 stroke-current",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "w-10/12",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "flex bg-gray-200 float-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "action.second_chance"
                                                                                  )
                                                                                ) +
                                                                                " - zerar respostas! "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              1860409
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.enableTabInvited
            ? _c(
                "vs-tab",
                { attrs: { label: _vm.$t("questionnaire.convidados") } },
                [
                  _c("vx-card", { staticClass: "mb-4" }, [
                    _c(
                      "div",
                      [
                        _c("Grid", {
                          ref: "invitedGrid",
                          attrs: {
                            multipleSelect: true,
                            service: _vm.questionnaireService,
                            route_grid_path: _vm.invitedPath,
                            order_column: "email",
                            direction: "asc",
                            fetchOnMounted: false,
                            showTotalText: "Total",
                            column_formats: {
                              status: function (val) {
                                return _vm.$t(
                                  "questionnaire.invite.status." + val
                                )
                              },
                            },
                            css_formats: {
                              status: function (val) {
                                switch (val) {
                                  case null:
                                    return ""
                                  case "p":
                                    return "text-warning"
                                  case "o":
                                    return "text-success"
                                  case "e":
                                    return "text-danger"
                                  case "s":
                                    return ""
                                  default:
                                    return ""
                                }
                              },
                            },
                          },
                          on: { changedSelection: _vm.changedSelectionInvited },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function (item) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex items-center justify-center",
                                      },
                                      [
                                        _c(
                                          "vs-dropdown",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              "vs-custom-content": "",
                                              "vs-trigger-click": "",
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                type: "filled",
                                                icon: "MoreVerticalIcon",
                                              },
                                            }),
                                            _c(
                                              "vs-dropdown-menu",
                                              [
                                                _vm.enableResendAction
                                                  ? _c("vs-dropdown-item", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "flex",
                                                          staticStyle: {
                                                            width: "150px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.resendInvite(
                                                                item.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-2/12",
                                                            },
                                                            [
                                                              _c(
                                                                "feather-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "SendIcon",
                                                                    svgClasses:
                                                                      "text-success h-5 w-5 mb-1 stroke-current",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-10/12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex bg-gray-200 float-left",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "action.resend_invite"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm.enableRemoveInviteAction &&
                                                (item.row.status === "p" ||
                                                  item.row.status === "e")
                                                  ? _c("vs-dropdown-item", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "flex",
                                                          staticStyle: {
                                                            width: "150px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.confirmRemoveInvite(
                                                                item.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-2/12",
                                                            },
                                                            [
                                                              _c(
                                                                "feather-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "TrashIcon",
                                                                    svgClasses:
                                                                      "text-danger h-5 w-5 mb-1 stroke-current",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-10/12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex bg-gray-200 float-left",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "action.delete"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2004789911
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "font-bold flex justify-center sm:justify-end mt-4 sm:mt-0",
                      },
                      [
                        _vm.enableResendSelected
                          ? _c(
                              "vs-button",
                              {
                                staticClass: "mr-2",
                                attrs: { icon: "send" },
                                on: { click: _vm.resendInviteSelected },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("action.resend_invite")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.enableResendAction
                          ? _c(
                              "vs-button",
                              {
                                staticClass: "mr-2",
                                attrs: { icon: "send", type: "border" },
                                on: { click: _vm.confirmResendInviteAll },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("action.resend_invite_all")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.enableResendAction
                          ? _c(
                              "vs-button",
                              {
                                staticClass: "flex-grow-0 flex-shrink-0 h-auto",
                                attrs: {
                                  icon: "send",
                                  color: "warning",
                                  type: "border",
                                },
                                on: { click: _vm.resendInvitePending },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("action.resend_invite_pending")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }